import { InboxOutlined } from '@ant-design/icons'
import { Upload, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { awsconfig, config } from 'configs'
import React, { useEffect, useState } from 'react'
import { imageSizeValidation } from 'utils'

const { Dragger } = Upload

const CustomImageUploader = ({
  defaultFile,
  onFileChange,
  isCropable = true,
  ratio = 1 / 1,
  name = '',
}) => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (defaultFile && defaultFile.fileName) {
      setFileList([
        {
          uid: '1',
          name: defaultFile?.fileName || 'default.jpg',
          status: 'done',
          url: `${awsconfig.bucketUrl}${defaultFile?.fileName}`,
          type: defaultFile?.fileMimeType || 'image/jpeg',
        },
      ])
    }
    // eslint-disable-next-line
  }, [defaultFile])

  const onChange = ({ fileList: newFileList }) => {
    const file = newFileList.length > 0 ? newFileList[0] : null

    // Adding Size Limitation
    if (file && file?.size) {
      const isValidFileSize = imageSizeValidation(file)
      if (!isValidFileSize.isValid) {
        message.destroy()
        message.error(
          config.imageMaxSizeLimitErrorMessage ||
            'Image Size limit is not Valid',
        )
        return false
      }
    }

    setFileList(newFileList)
    onFileChange(file)
    return true
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <>
      {isCropable ? (
        <ImgCrop rotate quality={1} aspect={ratio}>
          <Dragger
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            maxCount={1}
            accept="image/*"
            // beforeUpload={handleBeforeUpload}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text t-font-bold t-text-lg">
              {fileList.length > 0 ? 'Change' : 'Upload'} {name} Image
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </ImgCrop>
      ) : (
        <Dragger
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          maxCount={1}
          accept="image/*"
          beforeUpload={() => false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text t-font-bold t-text-lg">
            {fileList.length > 0 ? 'Change' : 'Upload'} Background Image
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          {fileList.length > 0 ? '+ Change' : '+ Upload'}
        </Dragger>
      )}
    </>
  )
}
export { CustomImageUploader }
