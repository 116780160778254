import React, { useEffect, useState } from 'react'
import { Card, Typography, Row, Col, Tag, Space, Button, Skeleton } from 'antd'
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  CheckCircleFilled,
  WarningOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getMyBusiness } from 'store/businessSlice/businessActions'

const { Title, Text } = Typography

const SuccessRegister = () => {
  const { user } = useAuthSelector()
  const [business, setBusiness] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const CardTitle = 'Application Submitted!'
  const CardDescription = 'Your business listing has been forwarded for review.'

  const getData = async () => {
    try {
      setLoading(true)
      const response = await getMyBusiness(user?.id)
      setBusiness(response)
    } catch (error) {
      console.error('Error fetching business details:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  // Destructure business details safely
  const {
    Id,
    Name = 'N/A',
    Description = 'N/A',
    Address1 = 'N/A',
    Address2 = 'N/A',
    MobileNumber = 'N/A',
    UserEmail = 'N/A',
    Website = '#',
  } = business || {}

  return (
    <>
      {loading ? (
        <Skeleton className="t-p-10" active paragraph={{ rows: 20 }} />
      ) : (
        <div style={{ minHeight: 'calc(100vh - 220px)', padding: '10px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '24px',
            }}
          >
            {/* Success Message */}
            <div style={{ textAlign: 'center', marginBottom: '24px' }}>
              <CheckCircleFilled
                style={{
                  fontSize: '200px',
                  color: '#1890ff',
                  marginBottom: '16px',
                }}
              />
              <Title level={2} style={{ fontWeight: 'bold' }}>
                {CardTitle}
              </Title>
              <Text>{CardDescription}</Text>
            </div>

            {/* Business Details Card */}
            <Card
              bordered={false}
              style={{
                width: '100%',
                maxWidth: '600px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                marginBottom: '24px',
                padding: '20px',
              }}
            >
              <Title level={4}>{Name}</Title>
              <Text>{Description}</Text>
              <div style={{ marginTop: '16px' }}>
                <Space direction="vertical" size="middle">
                  <div className="t-flex t-items-center t-justify-start">
                    <EnvironmentOutlined style={{ marginRight: '8px' }} />
                    <Text>
                      {Address1} {Address2}
                    </Text>
                  </div>
                  <div className="t-flex t-items-center t-justify-start">
                    <PhoneOutlined style={{ marginRight: '8px' }} />
                    <Text>{MobileNumber}</Text>
                  </div>
                  <div className="t-flex t-items-center t-justify-start">
                    <MailOutlined style={{ marginRight: '8px' }} />
                    <Text>{UserEmail}</Text>
                  </div>
                  <div className="t-flex t-items-center t-justify-start">
                    <GlobalOutlined style={{ marginRight: '8px' }} />
                    <Text>
                      <a
                        href={Website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {Website}
                      </a>
                    </Text>
                  </div>
                </Space>
              </div>
            </Card>

            {/* Membership Details */}
            <Row style={{ width: '100%', maxWidth: '600px' }}>
              <Col span={24}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Title level={4}>Your Membership</Title>
                  </Col>
                </Row>
                <Row justify="space-start" align="middle">
                  <WarningOutlined className="t-mr-2" />
                  <Text className="t-mr-4">Arboretum Westside Trail</Text>
                  <Tag color="orange">Pending</Tag>
                </Row>
              </Col>
            </Row>

            <Col span={24} className="t-text-right t-space-x-10 t-mt-10">
              <Button
                type="default"
                className="t-px-20"
                onClick={() => {
                  history.push(`/settings/register-business/${Id}`)
                }}
              >
                Edit
              </Button>
              <Button
                type="primary"
                className="t-px-20"
                onClick={() => {
                  history.push('/settings/manage-organization/business-detail')
                }}
              >
                Next
              </Button>
            </Col>
          </div>
        </div>
      )}
    </>
  )
}

export default SuccessRegister
