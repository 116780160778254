import { awsconfig } from 'configs'
import React, { useEffect, useState } from 'react'
import {
  Select,
  Button,
  Input,
  Checkbox,
  Row,
  Divider,
  Col,
  Card,
  Dropdown,
  Menu,
  Rate,
  message,
  Skeleton,
  Upload,
  Modal,
  Typography,
} from 'antd'
import { CopyBlock, dracula } from 'react-code-blocks'
import { getAverageRating, htmlCode } from 'utils'
import { useHistory } from 'react-router-dom'
import Api from 'api'
import {
  BankFilled,
  EnvironmentOutlined,
  ImportOutlined,
  MoreOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import QRCode from 'qrcode.react'
import {
  getAllBusinessesForOrganization,
  rateBusiness,
} from 'store/businessSlice/businessActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AddBusinessEntityModal } from '../../MyBusinessesConfiguration/MyBusinessEntities/BusinessInvitationList/AddBusinessEntityModal'
import { UploadStatisticModal } from '../../MyBusinessesConfiguration/MyBusinessEntities/BusinessInvitationList/uploadStatisticModal'

const { Title } = Typography
const { Option } = Select

const MyBusinessEntities = () => {
  const history = useHistory()
  const { organization } = useAuthSelector()
  const [loading, setLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [businessEntities, setBusinessEntities] = useState([])
  const [memberTier, setMemberTier] = useState('Gold')
  const [status, setStatus] = useState('Approved')
  const [sortBy, setSortBy] = useState('Distance')
  const [action, setAction] = useState('All')
  const [showAddBusinessModal, setShowAddBusinessModal] = useState(false)
  const [statisticModal, setStatisticModal] = useState(false)
  const [statisticData, setStatisticData] = useState()
  const [selectedBusinessIds, setSelectedBusinessIds] = useState([])
  const [qrModalVisible, setQrModalVisible] = useState(false)

  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)

  const changeModal = () => setEmbedURLModalVisible(!embedURLModalVisible)

  const handleCheckboxChange = (businessId, checked) => {
    setSelectedBusinessIds((prev) => {
      if (checked) {
        return [...prev, businessId]
        // eslint-disable-next-line
      } else {
        return prev.filter((id) => id !== businessId)
      }
    })
  }

  const handleFilterApply = () => {
    console.log('Filter applied with:', { memberTier, status, sortBy })
  }

  const handleActionApply = () => {
    console.log('Action applied with:', { action })
    if (action === 'All') {
      // Select all checkboxes
      setSelectedBusinessIds(
        filteredBusinessEntities.map((entity) => entity.Id),
      )
    } else {
      // Select only those with the matching status
      setSelectedBusinessIds(
        filteredBusinessEntities
          .filter((entity) => entity.BusinessStatus === action)
          .map((entity) => entity.Id),
      )
    }
  }

  const getAllBusinessEntities = async () => {
    setLoading(true)
    try {
      const response = await getAllBusinessesForOrganization(organization?.id)
      setBusinessEntities(response)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      message.error(
        error?.response?.data?.message ||
          'An error occur while fetching Business Listing data',
      )
      setLoading(false)
    }
  }

  const rateBusinessEntity = async (userId, Rating) => {
    setLoading(true)

    // Comments for each rating
    const comments = {
      1: 'Very Dissatisfied',
      2: 'Below Expectations',
      3: 'Average',
      4: 'Satisfied',
      5: 'Excellent',
    }

    try {
      const payload = {
        userId,
        Rating,
        RatingComment: comments[Rating] || 'No comment provided.', // Default comment if rating is missing
      }
      const response = await rateBusiness(payload)
      message.success(response?.message || 'Business rated successfully')
      await getAllBusinessEntities()
      setLoading(false)
    } catch (error) {
      console.error('Error rating business:', error)
      message.error(
        error?.response?.data?.message ||
          'An error occurred while rating the business',
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    if (organization?.id) {
      getAllBusinessEntities()
    }
    // eslint-disable-next-line
  }, [organization])

  const getStatusColorClass = (businessStatus) => {
    switch (businessStatus) {
      case 'Active':
        return 't-bg-[#E5F8F5] t-text-[#00BD9B]' // Green color for Active
      case 'Approved':
        return 't-bg-[#E5F8F5] t-text-[#00BD9B]' // Lighter green for Approved
      case 'Banned':
        return 't-bg-[#FDEDEB] t-text-[#EA4B36]' // Red color for Banned
      case 'Paused':
        return 't-bg-[#FDF2E6] t-text-[#E97E06]' // Orange color for Paused
      default:
        return 't-bg-[#E2E2E2] t-text-[#000000]' // Default to green if status is unknown
    }
  }

  const onFileUpload = (file) => {
    if (file.size < 2 * 1048576) {
      onFileSumbit(file)
    } else {
      message.error('File size should be less than 20 MB to upload')
    }
  }

  const onFileSumbit = (file) => {
    if (
      organization &&
      organization.id &&
      organization.Name &&
      organization.Email
    ) {
      const data = new FormData()
      data.append('uploadfile', file)
      data.append('OrganizationId', organization.id)
      data.append('OrganizationName', organization.Name)
      data.append('OrganizationEmail', organization.Email)
      setUploadLoading(true)
      Api.put('component/organization/upload-business-invitation-list', data)
        .then((res) => {
          message.success(res.message)
          setUploadLoading(false)
          setStatisticData(res?.statusData)
          setStatisticModal(true)
          getAllBusinessEntities()
        })
        .catch((error) => {
          console.log('error', error)
          message.error(
            error?.response?.data?.message ||
              'File cannot be processed due to an error',
          )
          setUploadLoading(false)
        })
    } else {
      message.error('Cound not get Organization data')
    }
  }

  const downloadFile = (data, type, filename) => {
    const blob = new Blob([data], { type: type.toString() })
    const blobURL = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.download = filename
    anchor.href = blobURL
    anchor.click()
  }

  // eslint-disable-next-line
  const filteredBusinessEntities = businessEntities.filter((entity) => {
    return (
      entity.Name.toLowerCase().includes(searchText.toLowerCase()) ||
      entity.Description.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  return (
    <>
      {/* Skeleton */}
      {loading ? (
        <Skeleton className="t-p-5" active paragraph={{ rows: 12 }} />
      ) : (
        <>
          {/* Filter Section */}
          {businessEntities.length > 0 && (
            <Row
              gutter={[24, 0]}
              className="t-p-2 t-flex t-flex-col md:t-flex-row t-justify-between"
            >
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="t-mb-2 md:t-mb-0"
              >
                <div className="t-flex t-flex-col md:t-flex-row t-justify-between t-items-center md:t-items-end t-gap-4 t-mt-5">
                  <div className="t-flex t-flex-col">
                    <p className="t-font-semibold t-text-md t-mb-2">
                      Member Tier
                    </p>
                    <Select
                      value={memberTier}
                      onChange={setMemberTier}
                      style={{ width: '150px' }}
                    >
                      <Option value="Silver">Silver</Option>
                      <Option value="Gold">Gold</Option>
                    </Select>
                  </div>

                  <div className="t-display-flex t-flex-col">
                    <p className="t-font-semibold t-text-md t-mb-2">Status</p>
                    <Select
                      value={status}
                      onChange={setStatus}
                      style={{ width: '150px' }}
                    >
                      <Option value="All">All</Option>
                      <Option value="Approved">Approved</Option>
                      <Option value="Invited">Invited</Option>
                      <Option value="Banned">Banned</Option>
                      <Option value="Pending">Pending</Option>
                    </Select>
                  </div>

                  <div className="t-display-flex t-flex-col">
                    <p className="t-font-semibold t-text-md t-mb-2">
                      Sorted By
                    </p>
                    <Select
                      value={sortBy}
                      onChange={setSortBy}
                      style={{ width: '150px' }}
                    >
                      <Option value="Name">Name</Option>
                      <Option value="Distance">Distance</Option>
                    </Select>
                  </div>

                  <Button type="primary" onClick={handleFilterApply}>
                    Apply Filter
                  </Button>
                </div>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="t-flex t-justify-center md:t-justify-end t-items-center md:t-items-end"
              >
                <div className="header-control t-mt-0">
                  <Input
                    className="header-search t-w-80"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Divider />

          <Row className="t-p-2 t-flex t-justify-between">
            <Col className="t-flex t-flex-col t-gap-2">
              {businessEntities.length > 0 && (
                <>
                  <div>
                    <p className="t-font-semibold t-text-md t-mb-2">Action</p>
                  </div>
                  <div>
                    <div className="t-flex t-gap-2 t-items-center t-justify-center">
                      <Checkbox />

                      <Select
                        value={action}
                        onChange={setAction}
                        style={{ width: '150px' }}
                      >
                        <Option value="All">All</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Invited">Invited</Option>
                        <Option value="Banned">Banned</Option>
                        <Option value="Pending">Pending</Option>
                      </Select>

                      <Button type="primary" onClick={handleActionApply}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Col>

            <Col className="t-flex t-gap-5 t-justify-end t-items-end">
              <Upload
                id="upload"
                customRequest={(e) => onFileUpload(e.file)}
                maxCount={1}
                showUploadList={false}
                accept=".csv"
              >
                <Button
                  loading={uploadLoading}
                  type="primary"
                  ghost
                  icon={<ImportOutlined />}
                >
                  Import
                </Button>
              </Upload>

              <Button
                type="primary"
                ghost
                icon={<UserAddOutlined />}
                onClick={() => setShowAddBusinessModal(true)}
              >
                Add Member
              </Button>
            </Col>
          </Row>

          <Divider />

          {/* Business Entities */}
          <Row gutter={[12, 24]} className="t-h-full t-w-full">
            {filteredBusinessEntities.length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <BankFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No business found</p>
                </div>
              </div>
            )}
            {filteredBusinessEntities.map((entity, index) => (
              <>
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    onClick={() => history.push(`/businesses/${entity.Id}`)}
                    hoverable
                    cover={
                      <img
                        alt="example"
                        className="t-w-full t-h-52 t-rounded-md t-object-cover"
                        src={
                          entity?.ProfilePicture?.fileName
                            ? awsconfig.bucketUrl +
                              entity?.ProfilePicture?.fileName
                            : 'https://placehold.co/800x480?text=Business+Entity+with+no+Profile+Picture'
                        }
                      />
                    }
                  >
                    {/* Left side Checkbox */}
                    <div className="t-absolute t-top-2 t-left-2">
                      <Checkbox
                        checked={selectedBusinessIds.includes(entity.Id)} // Check if the current business is selected
                        onChange={(e) => {
                          e.stopPropagation()
                          handleCheckboxChange(entity.Id, e.target.checked)
                        }}
                      />
                    </div>

                    {/* Right Side Dropdown Menu */}
                    <div className="t-absolute t-top-2 t-right-2">
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                e.domEvent.stopPropagation()
                                setQrModalVisible(true)
                              }}
                            >
                              Share QR
                            </Menu.Item>
                            <Menu.Item
                              key="2"
                              onClick={(e) => {
                                e.domEvent.stopPropagation()
                                setQrModalVisible(true)
                              }}
                            >
                              Print QR
                            </Menu.Item>
                            <Menu.Item
                              key="3"
                              onClick={(e) => {
                                e.domEvent.stopPropagation()
                                changeModal()
                              }}
                            >
                              Embed Code
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                        placement="bottomLeft"
                      >
                        <Button
                          shape="circle"
                          color="black"
                          icon={<MoreOutlined />}
                          size="small"
                          className="t-border-0 t-bg-white"
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Dropdown>
                    </div>

                    {/* Bottom side Badge */}
                    <div className="t-absolute t-top-[11rem] t-right-2">
                      <p
                        className={`
            t-text-[11px] t-font-bold t-inline-flex t-items-center t-px-2 t-py-1 t-rounded
            ${getStatusColorClass(entity.BusinessStatus)}
          `}
                      >
                        {entity.BusinessStatus}
                      </p>
                    </div>

                    {/* Title with Tooltip */}
                    <h5 className="t-text-base t-font-bold t-truncate">
                      {entity.Name}
                    </h5>

                    {/* Location with Icon above Rating */}
                    <div className="t-flex t-items-center t-py-1">
                      <EnvironmentOutlined className="t-mr-2" />
                      <span className="t-text-xs">{entity.Address1}</span>
                    </div>

                    {/* Rating */}
                    <div
                      className="t-py-1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Rate
                        value={getAverageRating(entity?.Rating)}
                        onChange={(stars) =>
                          rateBusinessEntity(entity.UserId, stars)
                        }
                      />
                    </div>

                    {/* Description */}
                    <p className="t-text-sm t-font-medium">
                      {entity.Description}
                    </p>
                  </Card>
                </Col>
                <Modal
                  centered
                  title="Embed URL"
                  width={600}
                  open={embedURLModalVisible}
                  onCancel={changeModal}
                  footer={null}
                >
                  <div>
                    <div className="t-my-2">
                      <Title level={4}>Copy iframe code</Title>
                      <CopyBlock
                        language="html"
                        text={`
                          <iframe
                          style="width: 100%; height: 100%"
                          src=${window.location.origin}/businesses/${entity.Id}
                          title=${entity.Name}
                          ></iframe>`}
                        theme={dracula}
                        wrapLines
                        codeBlock
                      />
                    </div>
                    <div className="t-my-2">
                      <Title level={4}>Copy code to paste in the website</Title>
                      <CopyBlock
                        language="html"
                        text={htmlCode(
                          `${window.location.origin}/businesses/${entity.Id}`,
                          'Business Entity',
                        )}
                        theme={dracula}
                        wrapLines
                        codeBlock
                      />
                    </div>
                  </div>
                </Modal>

                <Modal
                  centered
                  title="QR Code"
                  visible={qrModalVisible}
                  onCancel={() => setQrModalVisible(false)}
                  footer={null}
                >
                  <div className="t-flex t-items-center t-justify-center">
                    <QRCode
                      value={`https://development.dbmqzpln6q75p.amplifyapp.com/iceb1/${entity.Id}`}
                      size={200}
                    />
                  </div>
                </Modal>
              </>
            ))}
          </Row>
        </>
      )}
      <UploadStatisticModal
        isVisible={statisticModal}
        onClose={() => {
          setStatisticModal(false)
        }}
        statisticData={statisticData}
        downloadFile={downloadFile}
      />
      {showAddBusinessModal && (
        <AddBusinessEntityModal
          isVisible={showAddBusinessModal}
          onClose={() => setShowAddBusinessModal(false)}
          getData={() => getAllBusinessEntities()}
        />
      )}
    </>
  )
}

export { MyBusinessEntities }
