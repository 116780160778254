import React, { useState } from 'react'
import { Button, Dropdown, message, Skeleton, Space, Table } from 'antd'
import { searchDonnorRows } from 'utils'
import { EllipsisOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { makeRequest } from 'api/apiv2'
import Api from 'api'
import SendMessageModal from './SendMessageModal'
import RequestCompleteProfileModal from './RequestCompleteProfileModal'
import FeedbackModal from './FeedbackModal'

const CommunityList = ({
  loading,
  data,
  searchText,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const [sendMessageModal, setSendMessageModal] = useState(false)
  const [isVisibleCompleteProfile, setIsVisibleCompleteProfile] =
    useState(false)
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const history = useHistory()

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      DonorName: `${item.firstName} ${item.lastName}`,
      PhoneNumber: item.phoneNumber,
      Email: item.email,
      State: item.state ?? '--',
      Skills:
        Array.isArray(item.skills) && item.skills.length > 0
          ? item.skills
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(', ')
          : '--',
      Feedback: item.feedback ?? '--',
      key: item.iD,
      iD: item.iD,
    }))

    return formatedData || []
  }

  const items = [
    {
      label: (
        <span onClick={() => setSendMessageModal((prev) => !prev)}>
          Send Message
        </span>
      ),
      key: '0',
    },
    {
      label: (
        <span onClick={() => setIsVisibleCompleteProfile((prev) => !prev)}>
          Request Complete Profile
        </span>
      ),
      key: '1',
    },
    {
      label: (
        <span onClick={() => setIsVisibleFeedback((prev) => !prev)}>
          Add Feedback
        </span>
      ),
      key: '2',
    },
  ]

  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'PhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'State',
      dataIndex: 'State',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Skills',
      dataIndex: 'Skills',
      className: 'ant-table-row-cell-break-word',
      width: 250,
      ellipsis: true,
    },
    {
      title: 'Feedback',
      dataIndex: 'Feedback',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { iD }) => (
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <Space>
            <EllipsisOutlined
              style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
              onClick={() => setSelectedRowKeys([iD])}
            />
          </Space>
        </Dropdown>
      ),
    },
  ]

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((prevKeys) => [...prevKeys, record.key])
    } else {
      setSelectedRowKeys((prevKeys) =>
        prevKeys.filter((key) => key !== record.key),
      )
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: (selected) => {
      if (selected) {
        setSelectedRowKeys(data.map((item) => item.iD))
      } else {
        setSelectedRowKeys([])
      }
    },
  }

  const sendMessageSubmit = (payload) => {
    const updatedPayload = { ...payload, users: selectedRowKeys }
    setSendMessageModal(false)
    makeRequest(
      'put',
      'admin/volunteer-community/send-message',
      {},
      updatedPayload,
    )
      .then(() => {
        message.success('Message sent successfully')
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message || 'Error in sending message',
        )
      })
  }

  const sendCompleteProfile = () => {
    setIsVisibleCompleteProfile(false)
    makeRequest(
      'put',
      `admin/volunteer-community/${selectedRowKeys[0]}/request-complete-profile`,
      {},
      {},
    )
      .then(() => {
        message.success('Message sent successfully')
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message || 'Error in sending message',
        )
      })
  }

  const submitFeedback = (payload) => {
    setIsVisibleFeedback(false)
    makeRequest(
      'put',
      `admin/volunteer-community/${selectedRowKeys[0]}/feedback`,
      {},
      payload,
    )
      .then(() => {
        message.success('Feedback submitted successfully')
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message ||
            'Error in submitting feedback',
        )
      })
  }

  const onDownloadDonorList = () => {
    const list = data
      .filter((item) => selectedRowKeys.includes(item.iD))
      .map((item) => ({
        Name: `${item.firstName} ${item.lastName}`,
        Email: item.email,
        PhoneNumber: item.phoneNumber,
        MailingAddress: item.mailingAddress,
        State: item?.state,
        Feedback: item?.feedback || '',
        Skills: item?.skills?.join(',') || '',
      }))
    setDownloading(true)
    Api.post('subscriber/downloadDonorListFile', { list })
      .then((res) => {
        downloadFile(res, 'application/ms-excel', 'My-VolunteerList.csv')
        setDownloading(false)
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        setDownloading(false)
      })
  }

  const downloadFile = (payload, type, filename) => {
    const blob = new Blob([payload], { type: type.toString() })
    const blobURL = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.download = filename
    anchor.href = blobURL
    anchor.click()
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Space
        style={{
          float: 'right',
        }}
      >
        {selectedRowKeys.length > 0 && (
          <Button
            type="primary"
            size="sm"
            onClick={onDownloadDonorList}
            disabled={downloading}
          >
            {downloading ? 'Downloading file...' : 'Download'}
          </Button>
        )}
        <Button
          type="primary"
          size="sm"
          onClick={() =>
            history.push(
              '/settings/manage-organization/volunteer-communities-upload',
            )
          }
        >
          Import
        </Button>
      </Space>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchDonnorRows(formateData(data), searchText)}
        bordered={false}
        pagination
      />
      <SendMessageModal
        handler={setSendMessageModal}
        isOpen={sendMessageModal}
        submit={sendMessageSubmit}
      />
      <RequestCompleteProfileModal
        handler={setIsVisibleCompleteProfile}
        isOpen={isVisibleCompleteProfile}
        submit={sendCompleteProfile}
      />
      <FeedbackModal
        handler={setIsVisibleFeedback}
        isOpen={isVisibleFeedback}
        submit={submitFeedback}
      />
    </Skeleton>
  )
}

export { CommunityList }
