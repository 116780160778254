import React from 'react'
import { Card, Typography, Row, Col } from 'antd'
import QRCode from 'qrcode.react'
import {
  EnvironmentOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { config } from 'configs'
import { useParams } from 'react-router-dom'
import { Icon } from 'components'
import { getVolunteerWebAppUrl } from 'utils'
import { useVolunteerOpportunitySelector } from 'store/volunteerOpportunitySlice/volunteerOpportunityReducer'
import moment from 'moment'
import { useAuthSelector } from 'store/authSlice/authReducer'

const CheckInOutQrCode = () => {
  const params = useParams()

  const {
    organization: { OrgDomain },
  } = useAuthSelector()

  const { opportunities } = useVolunteerOpportunitySelector()

  const formateDate = (date) => moment(date).format('DD MMMM YY')
  const formateTime = (date) => moment(date).format('hh:mm A')

  const qrTitle = params.type === 'checkin' ? 'Check-In' : 'Check-Out'
  const qrContent = `${getVolunteerWebAppUrl()}/${OrgDomain}/opportunity/${
    params.id
  }`

  const { title, address, scheduleDate, volunteerDuration } =
    opportunities.find((opp) => opp.iD === params.id)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Card
        style={{ width: 650, height: 800, textAlign: 'center' }}
        bordered={false}
      >
        <Typography.Title level={4}>{title}</Typography.Title>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
          }}
        >
          <QRCode
            style={{
              width: '300px',
              height: '300px',
            }}
            bgColor={config.colors.tertiary}
            fgColor="#000000"
            value={qrContent}
          />
        </div>

        <Typography.Title level={3}>{qrTitle}</Typography.Title>

        <Card bordered style={{ marginTop: 16 }}>
          <Row align="middle" gutter={[16, 16]}>
            <Col>
              <EnvironmentOutlined style={{ marginRight: 8 }} />
              <Typography.Text>{address}</Typography.Text>
            </Col>

            <Col>
              <CalendarOutlined style={{ marginRight: 8 }} />
              <Typography.Text>{formateDate(scheduleDate)}</Typography.Text>
            </Col>

            <Col>
              <ClockCircleOutlined style={{ marginRight: 8 }} />
              <Typography.Text>{formateTime(scheduleDate)}</Typography.Text>
            </Col>

            <Col>
              <ClockCircleOutlined style={{ marginRight: 8 }} />
              <Typography.Text>
                Duration: {volunteerDuration} Hours
              </Typography.Text>
            </Col>
          </Row>
        </Card>

        <div
          style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}
        >
          <Icon src="pg_logo" width={100} height="auto" />
        </div>
      </Card>
    </div>
  )
}

export default CheckInOutQrCode
