import React, { useEffect, useState } from 'react'
import {
  Card,
  Skeleton,
  Table,
  Select,
  Upload,
  Button,
  message,
  Tooltip,
  Alert,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import './style.css'
import Api from 'api/apiv2'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import { useAuthSelector } from 'store/authSlice/authReducer'

const { Dragger } = Upload
const { Option } = Select

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
const isValidPhoneNumber = (phone) => /^(1\d{10}|92\d{10})$/.test(phone)

const validationRules = {
  email: { validate: isValidEmail, errorMessage: 'Invalid email address' },
  phoneNumber: {
    validate: isValidPhoneNumber,
    errorMessage: 'Invalid Phone number',
  },
  firstName: {
    validate: (name) => !!name?.trim(),
    errorMessage: 'First name cannot be empty',
  },
  lastName: {
    validate: (name) => !!name?.trim(),
    errorMessage: 'Last name cannot be empty',
  },
  state: {
    validate: (state) => !!state?.trim(),
    errorMessage: 'State cannot be empty',
  },
  skills: {
    validate: (skills) => !!skills?.trim(),
    errorMessage: 'Skills cannot be empty',
  },
}

const VolunteerCommunityUpload = () => {
  const uploadProps = {
    customRequest: ({ file }) => handleFileChange(file),
    showUploadList: false,
    maxCount: 1,
    accept: '.csv',
  }

  const { user } = useAuthSelector()
  const [csvData, setCsvData] = useState([])
  const [selectedHeaders, setSelectedHeaders] = useState({})
  const [validationErrors, setValidationErrors] = useState({})
  const [areAllHeadersSelected, setAreAllHeadersSelected] = useState(false)
  const [orgId, setOrgId] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [apiErrorMessage, setApiErrorMessage] = useState(null)

  const predefinedHeaders = [
    { key: 'firstName', label: 'FIRST NAME' },
    { key: 'lastName', label: 'LAST NAME' },
    { key: 'email', label: 'EMAIL' },
    { key: 'phoneNumber', label: 'CONTACT NUMBER' },
    { key: 'state', label: 'STATE' },
    { key: 'skills', label: 'SKILLS' },
  ]

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getUserOrganization(user?.email)
        setOrgId(response.id)
        setOrganization(response)
      } catch (error) {
        console.log('error', error)
        message.error('Something went wrong.')
      }
    }
    getData()
  }, [user])

  const handleFileChange = (file) => {
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const data = result.data.map((row) =>
            row.reduce((acc, cell, index) => {
              acc[`column_${index}`] = cell.trim()
              return acc
            }, {}),
          )
          setCsvData(
            data.filter((row) => Object.values(row).some((val) => val)),
          )
        },
        error: (error) => {
          console.error('Error parsing CSV:', error)
        },
      })
    }
  }

  const handleHeaderChange = (value, _, headerKey) => {
    setSelectedHeaders((prev) => ({
      ...prev,
      [headerKey]: value,
    }))
  }

  const validateData = () => {
    const errors = {}

    csvData.forEach((row, rowIndex) => {
      Object.entries(selectedHeaders).forEach(([headerKey, headerValue]) => {
        const columnKey = `column_${predefinedHeaders.findIndex(
          (h) => h.key === headerKey,
        )}`
        const validator = validationRules[headerValue]?.validate

        if (validator && !validator(row[columnKey])) {
          if (!errors[rowIndex]) errors[rowIndex] = {}
          errors[rowIndex][headerKey] =
            validationRules[headerValue].errorMessage
        }
      })
    })

    setValidationErrors(errors)

    if (Object.keys(errors).length > 0) {
      message.error('Validation failed. Hover over red cells to see details.')
    }

    return Object.keys(errors).length === 0
  }

  const formatData = () => ({
    organizationId: orgId,
    organizationName: organization?.Name,
    data: csvData.map((row) => {
      const mappedRow = {}
      Object.entries(selectedHeaders).forEach(([headerKey, headerValue]) => {
        let cellValue =
          row[
            `column_${predefinedHeaders.findIndex((h) => h.key === headerKey)}`
          ]

        if (headerValue === 'skills' && cellValue) {
          cellValue = cellValue.split(',').map((skill) => skill.trim())
        }

        if (headerValue === 'phoneNumber' && cellValue) {
          cellValue = `+${cellValue}`
        }

        mappedRow[headerValue] = cellValue || null
      })
      return mappedRow
    }),
  })

  const handleSubmit = async () => {
    if (!validateData()) {
      return
    }
    const formattedData = formatData()
    setUploading(true)
    setApiErrorMessage(null)
    Api.post(`/admin/volunteer-community/import`, formattedData)
      .then(() => {
        setUploading(false)
        setCsvData([])
        handleResetSubmit()
        message.success('Data uploaded successfully!')
      })
      .catch((error) => {
        setUploading(false)
        setApiErrorMessage(
          error?.response?.data?.error?.message || 'Unknown error occured.',
        )
        message.error('An Error Occured while uploading data.')
      })
  }

  useEffect(() => {
    const selected = predefinedHeaders.every(
      (header) => selectedHeaders[header.key],
    )
    setAreAllHeadersSelected(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHeaders])

  const isOptionDisabled = (option) =>
    Object.values(selectedHeaders).includes(option)

  const columns = predefinedHeaders.map((header) => ({
    title: (
      <Select
        value={selectedHeaders[header.key] || undefined}
        onChange={(value, e) => handleHeaderChange(value, e, header.key)}
        style={{ width: 150 }}
        placeholder="Select Header"
      >
        {predefinedHeaders.map((option) => (
          <Option
            key={option.key}
            value={option.key}
            disabled={isOptionDisabled(option.key)}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    ),
    dataIndex: `column_${predefinedHeaders.findIndex(
      (h) => h.key === header.key,
    )}`,
    key: header.key,
    render: (text, _, rowIndex) => {
      const errorMessage = validationErrors[rowIndex]?.[header.key]
      return (
        <Tooltip title={errorMessage || ''}>
          <div style={{ color: errorMessage ? 'red' : 'inherit' }}>
            {text || ''}
          </div>
        </Tooltip>
      )
    },
  }))

  const handleResetSubmit = () => {
    setSelectedHeaders({})
  }

  return (
    <Skeleton loading={false} active paragraph={{ rows: 12 }}>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={
            <div className="t-flex t-flex-wrap t-justify-between">
              <div>
                <h6 className="font-semibold t-text-2xl">
                  Import Volunteer List
                </h6>
              </div>
            </div>
          }
        >
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Only <strong>CSV</strong> files are accepted.
            </p>
          </Dragger>
          {apiErrorMessage && (
            <Alert
              message="Error in uploading data"
              type="error"
              showIcon
              description={apiErrorMessage}
            />
          )}
          {csvData.length > 0 && (
            <>
              <Table
                dataSource={csvData.map((row, index) => ({
                  ...row,
                  key: index,
                }))}
                columns={columns}
                className="table-responsive"
              />
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={!areAllHeadersSelected || uploading}
              >
                {uploading ? 'Uploading...' : 'Upload Data to Server'}
              </Button>

              {areAllHeadersSelected && (
                <Button
                  type="danger"
                  style={{ marginLeft: '10px' }}
                  onClick={handleResetSubmit}
                  disabled={uploading}
                >
                  Reset
                </Button>
              )}
            </>
          )}
        </Card>
      </div>
    </Skeleton>
  )
}

export { VolunteerCommunityUpload }
