import * as Yup from 'yup'

const numberValidation = new RegExp('[0-9]|,')

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  scheduleDate: Yup.date()
    .min(new Date(), 'Schedule Date should not be past date.')
    .typeError('Invalid Date')
    .required('Schedule Date is required'),
  maxRegistration: Yup.string()
    .matches(numberValidation, 'Invalid number')
    .required('This field is required.'),
  volunteerDuration: Yup.string()
    .matches(numberValidation, 'Invalid number')
    .required('This field is required.'),
  address: Yup.string()
    .required('Address is required')
    .min(10, 'Address should be atleast 10 character'),
  colorCode: Yup.string().required('Color Code is required'),
  description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  bannerImages: Yup.mixed().typeError('Banner image is not a file'),
  keywords: Yup.array()
    .of(
      Yup.string()
        .max(25, 'Each keyword must be at most 25 characters')
        .required('Keyword is required'),
    )
    .nullable(),
  enableVolunteerFeedback: Yup.boolean()
    .required('This field is required.')
    .default(true),
  showOnLandingPage: Yup.boolean()
    .required('This field is required.')
    .default(true),
  opportunityType: Yup.string().required().default('standalone'),
  invitedVolunteers: Yup.mixed().notRequired().default([]),
})

const volunteerOpportunityValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { volunteerOpportunityValidation }
