import React, { useEffect, useState } from 'react'
import { searchVolunteerOpportunitiesRows } from 'utils'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useHistory, Link } from 'react-router-dom'
import sample from 'assets/images/sample/fundraiser-default-image.png'
import { useVolunteerOpportunitySelector } from 'store/volunteerOpportunitySlice/volunteerOpportunityReducer'
import { getOrganizationOpportunities } from 'store/volunteerOpportunitySlice/volunteerOpportunityActions'

import { Table, Space, Dropdown, Popconfirm, message, Tag } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { makeRequest } from 'api/apiv2'
import moment from 'moment'

const MyOpportunity = ({ searchText, sortKey }) => {
  const { opportunities } = useVolunteerOpportunitySelector()
  const {
    organization,
    user: { email },
  } = useAuthSelector()
  const dispatch = useDispatch()
  const history = useHistory()
  const [opportunityId, setOpportunityId] = useState(null)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    dispatch(getOrganizationOpportunities(organization?.id, sortKey))
    // eslint-disable-next-line
  }, [organization, sortKey, refresh])

  const handleEventSelected = () => {
    history.push(`volunteer-opportunities/${opportunityId}`)
  }

  const goToEdit = () => {
    history.push(`settings/create-volunteer-opportunities/${opportunityId}`)
  }

  const removeOpportunity = () => {
    makeRequest('delete', `admin/opportunities/${opportunityId}`, {}, {})
      .then(() => {
        message.success('Opportunity removed successfully')
        setRefresh(!refresh)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message ||
            'Error in removing opportunity',
        )
      })
  }

  const duplicateOpportunity = () => {
    makeRequest(
      'post',
      `admin/opportunities/${opportunityId}/duplicate`,
      {},
      {
        createdBy: email,
      },
    )
      .then(() => {
        message.success('Opportunity duplicated successfully')
        setRefresh(!refresh)
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message ||
            'Error in duplicating opportunity',
        )
      })
  }

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      iD: item.iD,
      Title: item.title,
      Description: item.description,
      Schedule: item.scheduleDate,
      MaxRegistrations: item.maxRegistration,
      VolunteerDuration: item.volunteerDuration,
      Type: item.opportunityType,
      Banner: item.bannerImages.length > 0 ? item.bannerImages[0] : sample,
    }))

    return formatedData || []
  }

  const toTitleCase = (text) =>
    text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')

  const items = [
    {
      label: (
        <div
          onClick={(e) => handleEventSelected(e)}
          style={{ cursor: 'pointer' }}
        >
          View Detail
        </div>
      ),
      key: '0',
    },
    {
      label: (
        <div onClick={(e) => goToEdit(e)} style={{ cursor: 'pointer' }}>
          Edit
        </div>
      ),
      key: '1',
    },
    {
      label: (
        <Popconfirm
          title="Are you sure you want duplicate this opportunity?"
          onConfirm={duplicateOpportunity}
          okText="Yes"
          cancelText="No"
        >
          <span>Duplicate Event</span>
        </Popconfirm>
      ),
      key: '2',
    },
    {
      label: (
        <Popconfirm
          title="Are you sure you want remove this opportunity?"
          onConfirm={removeOpportunity}
          okText="Yes"
          cancelText="No"
        >
          <span>Delete Event</span>
        </Popconfirm>
      ),
      key: '3',
    },
  ]

  const columns = [
    {
      title: 'ID',
      dataIndex: 'iD',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (id) => (
        <Link
          to={`/volunteer-opportunities/${id}`}
          style={{ color: '#1890ff', textDecoration: 'underline' }}
          onClick={() => setOpportunityId(id)}
        >
          {id}
        </Link>
      ),
    },
    {
      title: 'TITLE',
      dataIndex: 'Title',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (text) => (
        <div
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'Description',
      className: 'ant-table-row-cell-break-word',
      responsive: ['md', 'lg', 'xl'],
      render: (text) => (
        <div
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'SCHEDULE',
      dataIndex: 'Schedule',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (date) => moment(date).format('MM/DD/YYYY | hh:mmA'),
    },
    {
      title: 'MAX. REGISTRATIONS',
      dataIndex: 'MaxRegistrations',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'VOLUNTEER DURATION',
      dataIndex: 'VolunteerDuration',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'TYPE',
      dataIndex: 'Type',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (text) => (
        <Tag
          style={{
            backgroundColor:
              text.toLowerCase() === 'standalone' ? '#E6F7FF' : '#F6FFED',
            color: text.toLowerCase() === 'standalone' ? '#1890FF' : '#52C41A',
            borderRadius: '6px',
            padding: '4px 12px',
            fontWeight: '500',
            fontSize: '14px',
            border: 'none',
          }}
        >
          {toTitleCase(text)}
        </Tag>
      ),
    },
    {
      title: 'BANNER',
      dataIndex: 'Banner',
      className: 'ant-table-row-cell-break-word',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (text) => (
        <img
          src={text}
          alt="Avatar"
          style={{
            width: '100%',
            maxWidth: '250px',
            height: 'auto',
            borderRadius: '5px',
            objectFit: 'cover',
          }}
        />
      ),
    },
    {
      title: 'ACTION',
      key: 'action',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, { iD }) => (
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <Space>
            <EllipsisOutlined
              style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
              onClick={() => setOpportunityId(iD)}
            />
          </Space>
        </Dropdown>
      ),
    },
  ]

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={searchVolunteerOpportunitiesRows(
          formateData(opportunities),
          searchText,
        )}
        bordered={false}
        pagination
      />
    </div>
  )
}

export { MyOpportunity }
