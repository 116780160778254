import { message } from 'antd'
import Api, { makeRequest } from 'api'
import { uploadFile } from 'store/authSlice/authActions'
import { sortByDate } from 'utils'
import { config } from 'configs'
import { businessActions } from './businessReducer'

export const getAssociations = async () => {
  const resopnse = await Api.get(`/business/associations`)
  return resopnse
}

export const getBusinessCategoriesList = async () => {
  const resopnse = await makeRequest(
    'get',
    `/component/business/business-categories-list`,
  )
  return resopnse?.results
}

export const registrationStatus = async (userId) => {
  const resopnse = await Api.get(
    `/business/registration-status?userId=${userId}`,
  )
  return resopnse.results
}

export const registerBusiness = async (data) => {
  let updatedData = data

  if (updatedData?.ProfilePicture) {
    const profilePictureFile = await uploadFile(updatedData.ProfilePicture)
    updatedData = {
      ...updatedData,
      ProfilePicture: {
        fileName: profilePictureFile.fileName,
        fileMimeType: profilePictureFile.fileMimeType,
      },
    }
  }

  if (updatedData?.BackgroundImage) {
    const backgroundImageFile = await uploadFile(updatedData.BackgroundImage)
    updatedData = {
      ...updatedData,
      BackgroundImage: {
        fileName: backgroundImageFile.fileName,
        fileMimeType: backgroundImageFile.fileMimeType,
      },
    }
  }

  const resopnse = await Api.post('/business', updatedData)
  return resopnse
}

export const updateBusiness = async (data) => {
  let updatedData = data
  if (updatedData.BackgroundImage && !updatedData.BackgroundImage.fileName) {
    const backgroundImageFile = await uploadFile(updatedData.BackgroundImage)
    updatedData = {
      ...updatedData,
      BackgroundImage: {
        fileName: backgroundImageFile.fileName,
        fileMimeType: backgroundImageFile.fileMimeType,
      },
    }
  }
  if (updatedData.ProfilePicture && !updatedData.ProfilePicture.fileName) {
    const profilePicture = await uploadFile(updatedData.ProfilePicture)
    updatedData = {
      ...updatedData,
      ProfilePicture: {
        fileName: profilePicture.fileName,
        fileMimeType: profilePicture.fileMimeType,
      },
    }
  }
  const response = await Api.put('/business/updateBusiness', updatedData)
  return response
}

export const getMyBusiness = async (userId) => {
  const resopnse = await Api.get(`/business?userId=${userId}`)
  return resopnse.results
}

export const getAllBusinessesForAdmin = () => (dispatch) => {
  dispatch(businessActions.setLoading(true))
  Api.get('/business/getAllBusinessesForAdmin')
    .then((res) => {
      dispatch(businessActions.setBusinessesForAdmin(res?.results))
      dispatch(businessActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(businessActions.setLoading(false))
    })
}

export const getOrganizationBusinesses = (id) => (dispatch) => {
  dispatch(businessActions.setLoading(true))
  Api.get(`/business/getOrganizationBusinesses?id=${id}`)
    .then((res) => {
      const items = res?.results
      if (items && items.length > 0) {
        dispatch(businessActions.setOrganizationBusinesses(items))
      } else {
        dispatch(businessActions.setOrganizationBusinesses([]))
      }
      dispatch(businessActions.setLoading(false))
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      dispatch(businessActions.setOrganizationBusinesses([]))
      dispatch(businessActions.setLoading(false))
    })
}

export const updateBusinessStatusByAdmin = (payload) => (dispatch) => {
  Api.put('/admin/updateBusinessStatus', payload)
    .then(() => {
      message.success('Updated business successfully')
      dispatch(getAllBusinessesForAdmin())
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(getAllBusinessesForAdmin())
      message.error('Something went wrong')
    })
}

export const getMyAssociationBusinessRequests =
  (organizationId) => (dispatch) => {
    dispatch(businessActions.setLoading(true))
    Api.get(`/business/getMyAssociationRequests/${organizationId}`)
      .then((res) => {
        console.log('Res', res)
        dispatch(businessActions.setAssociationRequests(res?.results))
        dispatch(businessActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(businessActions.setLoading(false))
      })
  }

export const changeBusinessRequestStatus =
  (Id, Status, GetData) => (dispatch) => {
    dispatch(businessActions.setLoading(true))
    Api.put(`/business/changeBusinessRequestStatus`, { Id, Status })
      .then(() => {
        message.success(`Business request has been ${Status} successfully`)
        dispatch(businessActions.setLoading(false))
        dispatch(getMyAssociationBusinessRequests())
        if (GetData) {
          GetData()
        }
      })
      .catch((err) => {
        console.log('Error', err)
        message.error(`Something went wrong`)
        dispatch(businessActions.setLoading(false))
      })
  }

export const getMyBusinessListing = async (organizationId) => {
  const resopnse = await Api.get(
    `/businessListings/getMyBusinessListing/${organizationId}`,
  )
  return resopnse.results
}

export const getAllBusinessesForOrganization = async (organizationId) => {
  const resopnse = await Api.get(
    `/business/getAllOrganizationBusinesses?id=${organizationId}`,
  )
  return resopnse.results
}

export const rateBusiness = async (payload) => {
  const response = await Api.post('/business/rate-business', payload)
  return response
}

export const getMyBannedListing = async () => {
  const resopnse = await Api.get(`/businessListings/getMyBannedListing`)
  return resopnse.results
}

export const getAllBusinesses =
  (zipCode, allLastEvaluatedKey = null) =>
  (dispatch) => {
    const isFirstLoad = allLastEvaluatedKey == null
    // if (isFirstLoad) dispatch(organizationActions.setLoading(true))
    Api.put('/business/getAllBusinesses', {
      limit: 50,
      lastEvaluatedKey: allLastEvaluatedKey,
    })
      .then((res) => {
        const data = res?.results?.data
        const key = res?.results?.LastEvaluatedKey
          ? res.results.LastEvaluatedKey
          : null
        dispatch(
          businessActions.setAllBusinesses({
            isFirstLoad,
            data,
            key,
            zipCode,
          }),
        )
        // dispatch(organizationActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        // dispatch(organizationActions.setLoading(false))
      })
  }

export const getNearbyBusinessesByZip =
  (zipCode, lastEvaluatedKey = null) =>
  (dispatch) => {
    const isFirstLoad = lastEvaluatedKey == null
    if (isFirstLoad) dispatch(businessActions.setLoading(true))
    Api.put('/business/nearByBusinesses', {
      zipCode,
      limit: 50,
      lastEvaluatedKey,
    })
      .then((res) => {
        const data = res?.results?.data
        const key = res?.results?.LastEvaluatedKey
          ? res.results.LastEvaluatedKey
          : null
        dispatch(businessActions.setBusinesses({ isFirstLoad, data, key }))
        dispatch(businessActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(businessActions.setLoading(false))
      })
  }

export const getBusinessDetails = (id) => (dispatch) => {
  dispatch(businessActions.setLoading(true))
  Api.get(`/business/getBusiness/${id}`)
    .then((res) => {
      dispatch(businessActions.setBusiness(res.results))
      dispatch(businessActions.setLoading(false))
    })
    .catch(() => {
      dispatch(businessActions.setBusiness(null))
      dispatch(businessActions.setLoading(false))
    })
}

export const requestBusinessService =
  (payload, setRequestLoading, action) => () => {
    setRequestLoading(true)
    Api.put('/publicBusiness/requestBusinessService', payload)
      .then(() => {
        message.success(
          'A request has been sent successfully.You will be notified once approved.',
        )
        setRequestLoading(false)
        if (action) {
          action()
        }
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something went wrong')
        setRequestLoading(false)
      })
  }

export const getBusinessServiceRequests = () => (dispatch) => {
  dispatch(businessActions.setLoading(true))
  Api.get(`/business/getBusinessServiceRequests`)
    .then((res) => {
      const sortedRequests = sortByDate(res?.results, 'CreatedDate')
      dispatch(businessActions.setBusinessServiceRequests(sortedRequests))
      dispatch(businessActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      message.error('Something went wrong')
      dispatch(businessActions.setLoading(false))
    })
}

export const getUserServiceRequests = () => (dispatch) => {
  dispatch(businessActions.setLoading(true))
  Api.get(`/business/getUserServiceRequests`)
    .then((res) => {
      const sortedRequests = sortByDate(res?.results, 'CreatedDate')
      dispatch(businessActions.setUserServiceRequests(sortedRequests))
      dispatch(businessActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      message.error('Something went wrong')
      dispatch(businessActions.setLoading(false))
    })
}

export const changeBusinessServiceRequestStatus =
  (Id, Status) => (dispatch) => {
    dispatch(businessActions.setLoading(true))
    Api.put(`/business/changeBusinessServiceRequestStatus`, { Id, Status })
      .then(() => {
        message.success('Service request status changed successfully')
        dispatch(businessActions.setLoading(false))
        dispatch(getBusinessServiceRequests())
        dispatch(getUserServiceRequests())
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something went wrong')
        dispatch(businessActions.setLoading(false))
      })
  }

export const createCounterOfferServiceRequest =
  (Id, Price, OfferNotes, setLoading, action) => (dispatch) => {
    setLoading(true)
    Api.put(`/business/createCounterOfferServiceRequest`, {
      Id,
      Price,
      OfferNotes,
    })
      .then(() => {
        message.success('Service request status changed successfully')
        setLoading(false)
        if (action) action()
        dispatch(getBusinessServiceRequests())
      })
      .catch((error) => {
        console.log('error', error)
        message.error('Something went wrong')
        setLoading(false)
      })
  }

export const payBusinessServiceStripe =
  (payload, setLoading, actions) => () => {
    setLoading(true)
    Api.post('/component/business-service/request-payment-stripe', payload)
      .then(() => {
        setLoading(false)
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || 'An Error Occured')
        setLoading(false)
      })
  }

export const payBusinessServicePaypal =
  (payload, setLoading, actions) => () => {
    setLoading(true)
    Api.post('/component/business-service/request-payment-paypal', payload)
      .then(() => {
        setLoading(false)
        if (actions) {
          actions()
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || 'An Error Occured')
        setLoading(false)
      })
  }

export const getBusinessInvitationByOrganization = async (Id) => {
  makeRequest({
    method: 'GET',
    url: `/business/getBusinessInvitationByOrganization/${Id}`,
  })
    .then((res) => {
      console.log('res', res)
    })
    .catch((err) => {
      console.log('err', err)
    })
}
