import React from 'react'
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { MoreOutlined } from '@ant-design/icons'

const Services = ({
  services,
  setAddServiceModal,
  editService,
  deleteService,
}) => {
  // const deleteItem = (key) => {
  //   const updatedData = auctionItemsList.filter((item) => key !== item.key)
  //   setAuctionItemsList(updatedData)
  // }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'Title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { Id }) => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu className="t-w-full">
                <Menu.Item
                  key="1"
                  onClick={() => {
                    console.log('Id', Id)
                    editService(Id)
                  }}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => {
                    console.log('Id', Id)
                  }}
                >
                  <Popconfirm
                    title="Are you sure you want to delete this service?"
                    onConfirm={() => deleteService(Id)}
                  >
                    Delete
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
            placement="bottomLeft"
          >
            <Button
              color="black"
              icon={<MoreOutlined />}
              size="large"
              className="t-border-none t-shadow-none hover:t-bg-transparent hover:t-text-black"
            />
          </Dropdown>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Col span={24}>
        <Col
          span={24}
          className="t-py-8 t-px-5 t-bg-[#F8F8F8] t-rounded-2xl t-w-full t-mb-4"
        >
          <Row className="t-flex t-items-center t-justify-between">
            <div>
              <Typography.Title level={4} className="t-font-bold">
                Service List
              </Typography.Title>
              <Typography.Text type="secondary">
                You need to define room availability for each day of week for
                this room
              </Typography.Text>
            </div>
            <div>
              {services.length < 5 ? (
                <Button
                  type="default"
                  block
                  onClick={() => setAddServiceModal(true)}
                >
                  + Add Services
                </Button>
              ) : (
                <p className="t-text-red-600">
                  Maximum Service Item limit reached
                </p>
              )}
            </div>
          </Row>
        </Col>
      </Col>
      <Col span={24}>
        <Table
          tableLayout="auto"
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={services}
          className="t-border-2 t-rounded-lg"
          pagination={false}
        />
      </Col>
    </>
  )
}

export default Services
