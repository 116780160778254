import React, { useEffect } from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd'
import { businessServiceValidation } from 'validations'
import { v4 as uuidv4 } from 'uuid'

const { TextArea } = Input

const initialValues = {
  Title: '',
  Description: '',
  Deliverable: '',
}

const AddServiceModal = ({
  visible,
  close,
  services,
  setServices,
  selectedServiceId,
  setSelectedServiceId,
}) => {
  const [form] = Form.useForm()

  const selectedService = services?.find(
    (item) => item.Id === selectedServiceId,
  )

  useEffect(() => {
    if (selectedService) {
      form.setFieldsValue(selectedService)
    }
  }, [form, selectedService])

  const onFinish = (values) => {
    if (selectedService) {
      const updatedServices = services.map((item) => {
        if (item?.Id === selectedServiceId) {
          return {
            Id: selectedServiceId,
            ...values,
          }
        }
        return item
      })
      setServices(updatedServices)
      setSelectedServiceId(null)
    } else {
      const item = {
        Id: uuidv4(),
        ...values,
      }
      setServices([...services, item])
    }
    form.resetFields()
    close()
  }

  return (
    <Modal
      centered
      keyboard
      title="Add Service"
      open={visible}
      onCancel={() => {
        form.resetFields()
        close()
      }}
      footer={null}
      destroyOnClose
    >
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="add-items"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item
                name="Title"
                label="Service Title"
                colon={false}
                rules={[businessServiceValidation]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Description"
                label="Description"
                colon={false}
                rules={[businessServiceValidation]}
              >
                <TextArea
                  placeholder="Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="t-flex t-items-center t-justify-end">
                <Button type="primary" htmlType="submit">
                  {selectedService ? 'Update Service' : 'Add Service'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </Modal>
  )
}

export default AddServiceModal
